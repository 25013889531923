<template>
  <!-- 云防护弹窗 -->
  <client-only>
    <el-dialog
      width="460px"
      center
      :visible.sync="protectShow"
      :close-on-click-modal="false"
      title
      custom-class="pretect-dialog-box"
    >
      <div slot="title">
        <div class="protect-title">{{ $t('common.veri') }}</div>
        <!-- 网易云验证 -->
        <div v-show="netStatus && type==1" id="netwrap" style="position:relative">
          <div id="captcha"></div>
        </div>
        <!-- google 验证 -->
        <div
          v-show="reStatus && type==2"
          id="recaptcha"
          class="g-recaptcha"
          :data-sitekey="siteKey[siteType]"
          data-callback="grecaptchaCallback"
          data-error-callback="greErrorCallback"
          data-expired-callback="greExpireCallback"
        ></div>
      </div>
      <div
        @click="changeType"
        v-show="netStatus && reStatus"
        class="change"
      >{{ $t('common.change_veri') }}</div>
    </el-dialog>
  </client-only>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      type: 1,
      //谷歌验证key
      siteKey: {
        login: 'key1',
        register: 'key2',
        forget: 'key3'
      }
    }
  },
  props: {
    netStatus: {
      type: Boolean,
      default: false
    },
    reStatus: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    siteType: {
      type: String,
      default: 'login'
    },
    showType: {
      type: Number,
      default: 1,
      validator: num => {
        return [1, 2].includes(num)
      }
    }
  },
  watch: {
    showType(val) {
      this.type = val
    }
  },
  computed: {
    ...mapGetters(['getLang']),
    captchaLang() {
      var lang = this.getLang || 'zh'
      return lang
    },
    protectShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  mounted() {
    var that = this
    window.grecaptchaCallback = function(token) {
      that.protectShow = false
      grecaptcha.reset()
      that.$emit('recallback', token)
    }
    window.greErrorCallback = function(val) {
      console.log('error', val)
    }
    window.greExpireCallback = function(val) {
      console.log('expire', val)
    }
    let recapurl = 'https://www.recaptcha.net'
    if (location.hostname.indexOf('weex.com') > -1) {
      recapurl = 'https://www.google.com'
    }
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `${recapurl}/recaptcha/api.js?hl=${this.captchaLang}`
    script.async = 'async'
    script.defer = 'defer'
    document.body.appendChild(script)
  },
  methods: {
    changeType() {
      let max = 2
      let type = this.type
      type++
      if (type > max) {
        type = 1
      }
      this.type = type
      this.$emit('update:showType', this.type)
    }
  }
}
</script>
<style lang="less">
.pretect-dialog-box {
  box-sizing: content-box;
  //padding-right: 15px;
  #netwrap {
    height: 360px;
  }
  .yidun_popup.yidun_popup--light .yidun_modal {
    border: 0;
    box-shadow: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .yidun_popup.yidun_popup--light .yidun_popup__mask {
    display: none;
  }
  .yidun_popup.yidun_popup--light .yidun_modal__body {
    padding: 0;
  }
}
.g-recaptcha {
  margin-left: 25px;
  margin-top: 20px;
}

.protect-title {
  font-size: 20px;
  color: @gray-P1;
}

.change {
  margin-left: 30px;
  padding-bottom: 20px;
  cursor: pointer;
  color: #2483ff;
}
</style>
