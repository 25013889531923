<template>
  <div class="container-wrap" :style="!imgList.length && imgListHeight" :class="newTheme">
    <div v-if="bannerLoading && !imgList.length" :class="imgList.length ? 'default-wrap':'default-wrap default-wrap-height'" >
      <img
        class="img"
        v-if="newTheme == 'black' && locale === 'zh-CN'"
        src="~/assets/img/leftDefaultImg/cn-black.png"
      />
      <img
        class="img"
        v-if="newTheme == 'white' && locale === 'zh-CN'"
        src="~/assets/img/leftDefaultImg/cn-white.png"
      />
      <img
        class="img"
        v-if="newTheme == 'black' && locale === 'zh-TW'"
        src="~/assets/img/leftDefaultImg/tw-black.png"
      />
      <img
        class="img"
        v-if="newTheme == 'white' && locale === 'zh-TW'"
        src="~/assets/img/leftDefaultImg/tw-white.png"
      />
      <img class="img" v-if="newTheme == 'black' && locale === 'en'" src="~/assets/img/leftDefaultImg/en-black.png" />
      <img class="img" v-if="newTheme == 'white' && locale === 'en'" src="~/assets/img/leftDefaultImg/en-white.png" />
      <img class="img" v-if="newTheme == 'black' && locale === 'vi'" src="~/assets/img/leftDefaultImg/en-black.png" />
      <img class="img" v-if="newTheme == 'white' && locale === 'vi'" src="~/assets/img/leftDefaultImg/en-white.png" />
      <img class="img" v-if="newTheme == 'black' && locale === 'ko'" src="~/assets/img/leftDefaultImg/ko-black.png" />
      <img class="img" v-if="newTheme == 'white' && locale === 'ko'" src="~/assets/img/leftDefaultImg/ko-white.png" />
    </div>
    <!-- <el-carousel
      ref="carouselTax"
      v-if="bannerLoading&&imgList.length"
      :indicator-position="imgList.length > 2 ? 'outside' : 'none'"
      height="570px"
      arrow="never"
      @change="changeCarousel"
    >
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <a :href="item.url" target="blank">
          <img class="carousel-img" :src="item.imgUrl" alt="" />
        </a>
      </el-carousel-item>
      <template v-if="imgList.length === 2">
        <el-carousel-item v-for="(item, index) in imgList" :key="index + 'copy'">
          <a :href="item.url" target="blank">
            <img class="carousel-img" :src="item.imgUrl" alt="" />
          </a>
        </el-carousel-item>
      </template>
    </el-carousel>
    <ul
      v-if="imgList.length === 2"
      class="el-carousel__indicators el-carousel__indicators--horizontal el-carousel__indicators--outside"
    >
      <li
        :class="index === indexActive ? 'is-active' : ''"
        class="el-carousel__indicator el-carousel__indicator--horizontal"
        v-for="(group, index) in imgList"
        :key="index"
        @click="handleCarousel(index)"
      >
        <button class="el-carousel__button"></button>
      </li>
    </ul> -->

    <!-- 广告位改版 -->
    <!-- <div v-if="imgList && imgList[0]?.pictures?.length" class="advertisement-box">
      <div v-for="(picture, index) in imgList[0].pictures" :key="index">
        <div>
          <p class="advertisement-name" v-if="index === 0">WEEX & <span>ApexCrypto</span></p>
          <p class="advertisement-text" v-if="index === 0">{{ imgList[0]?.pictures[0]?.picture[0]?.copywriting }}</p>
          <img :src="imgList[0]?.pictures[index]?.picture[0]?.url" alt="">
        </div>
      </div>
    </div> -->

    <!-- 广告位改版 -->
    <!-- <p class="advertisement-text">Join the team & enjoy 0% maker fee</p> -->
    <div v-if="imgList && imgList[0]?.pictures?.length" class="advertisement-box">
      <a v-for="(picture, index) in imgList[0]?.pictures" :key="index" @click="goUrl(picture?.url)">
        <p class="advertisement-name" v-if="index === 0 && agentName">WEEX & <span>{{agentName}}</span></p>
        <p class="advertisement-text" :class="!agentName && 'advertisement-text-one'" v-if="index === 0">{{ picture?.picture[0]?.copywriting }}</p>
        <img :class="{mt: index === 0 && (agentName || picture?.picture[0]?.copywriting)}" :src="newTheme == 'black' ? picture?.picture[0]?.urlBlack : picture?.picture[0]?.url" alt="">
      </a>
    </div>
    <div v-else class="advertisement-bg"></div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getPictureList } from '~/server/common/index.js';
export default {
  name: 'LeftCarouselImg',
  props: {
    language: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 1
    },
    vipCodeStr :{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bannerLoading: false,
      imgList: [],
      indexActive: 0,
      agentName:"",
      imgListHeight: {
        height: '100vh'
      }
    };
  },
  computed: {
    ...mapState(['locale', 'newTheme', 'inviteCodeInfo'])
  },
  mounted() {
    this.getInitData();
  },
  methods: {
    getInitData() {
      const language = { 'en': 0, 'zh-CN': 1, 'ko': 3, 'zh-TW': 5 , 'vi': 4 }[this.locale];
      const { vipCode = '' } = this.inviteCodeInfo;
      this.vipCodeStr = this.$route.query.vipCode || vipCode; // 註冊登入左側的活動圖片vipCode從url獲取,不使用user在邀請碼的欄位輸入的值
      getPictureList({
        language,
        type: this.type,
        vipCode : this.vipCodeStr
      })
        .then(res => {
          if (res.data) {
            if(this.agentName==''){
              this.agentName = res.data[0].agentName
            }
            this.imgList = res.data.map(item => {
              const pictures = JSON.parse(item.pictures);
              const parsedPictures = pictures.map(pic => {
                return {
                  module: pic.module,
                  url: pic.url,
                  picture: JSON.parse(pic.picture)
                };
              });
              item.pictures = parsedPictures;
              return item;
            });
          }
        })
        .finally(() => {
          this.bannerLoading = true;
        });
    },
    changeCarousel(a) {
      if (this.imgList.length === 2) {
        a === 2 ? (this.indexActive = 0) : a === 3 ? (this.indexActive = 1) : (this.indexActive = a);
      } else {
        this.indexActive = a;
      }
    },
    handleCarousel(index) {
      if (this.$refs.carouselTax) {
        this.$refs.carouselTax.setActiveItem(index);
      }
    },
    addUrlQueryParam(url, key, value) {
      const urlObj = new URL(url);
      urlObj.searchParams.set(key, value);
      return urlObj.toString();
    },
    goUrl(url){
      if (!url) {
        return
      }
      const newUrl = this.vipCodeStr ? this.addUrlQueryParam(url, 'vipCode', this.vipCodeStr) : url
      // return newUrl
      window.open(newUrl)
    }
  },
  watch: {
    vipCodeStr(newValue, oldValue){
      if (newValue.length >= 3) { //不需要頻繁的請求 改為vipCode長度大於3才請求
        this.getInitData();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container-wrap {
  height: 100%;
  position: relative;
  overflow: hidden;
  color: #fff;
  .default-wrap {
    width: 380px;
    .img {
      width: 100%;
      height: 570px;
    }
  }
  .carousel-img {
    width: 380px;
    height: 570px;
  }
  &.white {
    color: #000;
    background: #fff;
  }
  .default-wrap-height {
    height: 100vh;
  }
  // ::v-deep .el-carousel__indicator {
  //   .el-carousel__button {
  //     width: 6px;
  //     height: 6px;
  //     background: #777989;
  //     border-radius: 50%;
  //   }
  //   &.is-active {
  //     .el-carousel__button {
  //       width: 20px;
  //       height: 6px;
  //       background: #F8C71B;
  //       border-radius: 3px;
  //     }
  //   }
  // }
  .advertisement-bg{
    height: 500px;
  }
  .advertisement-box {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    cursor: pointer;
    div {
      width: 100%;
      margin-bottom: 22px;
      border-radius: 24px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        background-size: cover;
        margin-top: 0;
      }
    }

    > div:nth-child(1) {
      // height: 100px;
      position: relative;
      .advertisement-name {
        position: absolute;
        right: 20px;
        top: 25px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        span {
          color: @yellow-P3;
        }
      }
      .advertisement-text {
        position: absolute;
        right: 20px;
        top: 49px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
      .advertisement-text-one{
        top: 39px;
      }
    }
    > div:nth-child(2) {
      // height: 256px;
    }

    > div:nth-child(3) {
      // height: 573px;
    }

    > div:nth-child(4) {
      // height: 104px;
    }
  }
}
</style>
