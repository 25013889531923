<template>
  <div class="area-phone-box">
    <VuePerfectScrollbar class="area-phone-content" :style="'max-height: ' + maxHeight + 'px;'" :settings="settings" tagname="div">
      <div class="select-search-main">
        <select-search v-if="openSearch" class="select-search-wrap" v-model="searchStr"></select-search>
      </div>
      <ul>
        <li v-for="(item, index) in searchList" :class="item.active && 'active'" :key="index" @click="choose(item)">
          <span class="area-country">{{ item.countryName }}</span
          ><span class="area-num">{{ item.areaCode }}</span>
        </li>
      </ul>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import { areaCodeList } from "../server/common/index.js";
import { config } from "../config/enum.conf.js";
let i18nconf = require("../config/request.i18n.conf.js");
import SelectSearch from "~/components/common/SelectSearch";
import { mapGetters } from 'vuex'
export default {
  components: {
    SelectSearch
  },
  props: {
    areaCode: {
      type: [String, Number],
      default: 86
    },
    maxHeight: {
      type: [String, Number],
      default: 280
    },
    openSearch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 100
      },
      list: [],
      searchStr: '',
      myAreaCode: this.areaCode,
    };
  },
  computed: {
    ...mapGetters(['getLang']),
    searchList() {
      if(this.searchStr) {
        return this.list.filter(item => item.countryName.toUpperCase().indexOf(this.searchStr.toUpperCase()) > -1 || item.areaCode.indexOf(this.searchStr) > -1);
      }
      return this.list;
    },
  },
  mounted() {
    this.list = [];
    let languageType = i18nconf(this.getLang).lang;
    areaCodeList({ languageType }).then(res => {
      if (res.code === config.error.SUCCESS) {
        if (languageType == 3) {
          this.myAreaCode = "82";
        }
        if (languageType == 2) {
          this.myAreaCode = "81";
        }
        if (languageType == 0) {
          this.myAreaCode = "1";
        }
        if (languageType == 4) {
          this.myAreaCode = "84";
        }
        if (languageType == 5) {
          this.myAreaCode = "886";
        }
        if (languageType == 6) {
          this.myAreaCode = "7";
        }
        if (languageType == 7) {
          this.myAreaCode = "34";
        }
        this.list = res.data.areaCodeList;
        this.list.forEach(item => {
          this.$set(item, "active", false);
          if (item.areaCode == this.myAreaCode.toString()) {
            this.$emit("selected", item);
            item.active = !item.active;
          }
        });
      }
    });
  },
  methods: {
    choose(curr) {
      let _this = this;
      if (curr.areaCode == _this.areaCode.toString()) {
        this.$emit("selected", curr);
        this.searchStr = '';
        return;
      }
      this.list.forEach(item => {
        if (curr.areaCode === item.areaCode) {
          item.active = !item.active;
        } else {
          this.$set(item, "active", false);
        }
      });
      this.searchStr = '';
      this.$emit("selected", curr);
    }
  }
};
</script>

<style lang="less" scoped>
.area-phone-box {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9;
  border: 1px solid #eaeced;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 #e9e9e9;
  transition: all 0.35s;
  .area-phone-content {
    overflow: hidden;

    .select-search-main{
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding-top: 10px;
      background: #fff;
      .select-search-wrap {
        padding: 0 20px;
        background: #fff;
      }
    }

    ul {
      li {
        height: 34px;
        padding: 0 20px;
        text-align: left;
        border: 0;
        font-size: 14px;
        line-height: 34px;
        color: @gray-P1;
        cursor: pointer;
        &:hover {
          color: @yellow-P3;
          background-color: #f6faff;
        }
        &.active {
          color: @yellow-P3;
          background-color: transparent;
        }
        .area-num {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
