<template>
  <client-only>
    <div>
      <div class="select-search-box">
        <span class="select-search-prefix">
          <i class="iconfont icon-sousuo"></i>
        </span>
        <input type="text" autocomplete="off" name="dnSearchStr" style="display:none; width:0; height:0" />
        <input class="select-search-input" autocomplete="off" type="text" name="dnSearchStr" v-bind="$attrs" v-on="mylisteners" :placeholder="$t('common.search')" />
      </div>
    </div>
  </client-only>
</template>

<script>
export default {
  computed: {
    mylisteners: function() {
      let _this = this;
      return {
        ..._this.$listeners,
        input(event) {
          _this.$emit("input", event.target.value);
        },
        blur() {
          _this.inputfous = false;
          _this.$emit("blur");
        },
        focus() {
          _this.inputfous = true;
          _this.$emit("focus");
        }
      };
    }
  }
};
</script>

<style lang="less">
.select-search-box {
  .flex();
  .pr();
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-bottom: 10px;
  background-color: #fff;

  .select-search-prefix{
    .pa();
    top: 1px;
    left: 10px;
    .icon-sousuo{
      font-size: 14px;
      color: #C6CBD3;
    }
  }

  // WebKit browsers
  input::-webkit-input-placeholder {
    font-size: 14px;
    color: #c6cbd3 !important;
  }

  // Mozilla Firefox 4 to 18
  input:-moz-placeholder {
    font-size: 14px;
    color: #c6cbd3 !important;
  }

  // Mozilla Firefox 19+
  input::-moz-placeholder {
    font-size: 14px;
    color: #c6cbd3 !important;
  }

  // Internet Explorer 10+
  input:-ms-input-placeholder {
    font-size: 14px;
    color: #c6cbd3 !important;
  }
  input::-ms-clear {display:none;}
  input::-ms-reveal {display:none;}

  .select-search-input{
    display: inline-block;
    width: 100%;
    padding: 7px 0 7px 28px;
    font-size: 14px;
    line-height: normal;
    border: 1px solid #dee1e4;
    vertical-align: middle;
    outline: none;
    transition: all .35s;

    &:focus{
      border-color: @yellow-P3;
    }
  }
}
</style>
