<template>
  <div>
    <!-- <div v-if="!checked" class="readMeCheck" :class="{'is-disabled': disabled}" @click="setChecked"></div> -->
    <div @click="setChecked">
      <img v-if="checked" class="readMeCheckImg" :class="{'is-disabled': disabled}" src="~/assets/img/public/icon_select_new.svg" />
      <img v-else class="readMeCheckImg" :class="{'is-disabled': disabled}" src="~/assets/img/public/icon_unselect_new.svg" />
    </div>
  </div>
</template>

<script>

export default {
  props: ['isChecked', 'disabled'],
  data() {
    return {
      checked: this.isChecked
    }
  },
  methods: {
    setChecked() {
      if(this.disabled) return
      this.checked = !this.checked;
      this.$emit('update:isChecked', this.checked);
    }
  },
  watch:{
    isChecked(val){
      this.checked=val;
    }
  }
}
</script>

<style lang="less">
  .readMeCheck {
    width: 14px;
    height: 14px;
    background: @black-btn-tertiary-default;
    border-radius: 3px;
    margin-top: 3px;
    margin-right: 8px;
    cursor: pointer;
    &.is-disabled {
      cursor: not-allowed;
    }
  }

  .readMeCheckImg {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-right: 8px;
    cursor: pointer;
    &.is-disabled {
      cursor: not-allowed;
    }
  }
</style>
