import { getInstance } from '../../utils/doRequest';

const commonInstance = getInstance('');

export const withdrawCancel = data => {
  return commonInstance.post('/v1/if/withdraw/cancel', data);
};

/**
 * 校验资金密码
 * @param {*} data object
 */
export const checkCapitalPwd = data => {
  return commonInstance.post('/v1/user/security/trade-pwd/check-trade-pwd', data);
};

export const kycAreaList = data => {
  return commonInstance.post('/v1/user/kyc/kycAreaList', data);
};

/**
 *  启停手机第一步
 */
export const mobileEnable = data => {
  return commonInstance.post('/v1/user/security/mobile/mobile-enable', data);
};

/**
 *  启停手机第二步验证
 */
export const verifyMobileEnable = data => {
  return commonInstance.post('/v1/user/security/mobile/verify-mobile-enable', data);
};

/**
 *  启停邮箱第一步
 */
export const emailEnable = data => {
  return commonInstance.post('/v1/user/security/email/email-enable', data);
};

/**
 *  启停邮箱第二步验证
 */
export const verifyEmailEnable = data => {
  return commonInstance.post('/v1/user/security/email/verify-email-enable', data);
};

/**
 *  启停谷歌第一步
 */
export const googleEnable = data => {
  return commonInstance.post('/v1/user/security/google/google-enable', data);
};

/**
 *  启停谷歌第二步验证
 */
export const verifyGoogleEnable = data => {
  return commonInstance.post('/v1/user/security/google/verify-google-enable', data);
};

/**
 * 设备列表
 * @param {*} data
 * @returns
 */
export const equipmentList = data => {
  return commonInstance.post('/v1/user/device/managementList', data);
};

/**
 * 设备详情列表
 * @param {*} data
 * @returns
 */
export const equipmentDetail = data => {
  return commonInstance.post('/v1/user/device/detail', data);
};

/**
 * 删除设备
 * @param {*} data
 * @returns
 */
export const equipmentDelete = data => {
  return commonInstance.post('/v1/user/device/delete', data);
};

/**
 * 活动框架列表
 */
export const frameList = data => {
  return commonInstance.post('/v1/act/frame/list', data);
};

/**
 * 活动详情
 */
export const actGet = data => {
  return commonInstance.post('/v1/act/get', data);
};

/**
 * 我的福利列表
 */
export const rewardsWelfare = data => {
  return commonInstance.post('/v1/assets/rewards/welfare', data);
};

/**
 * 获取活动奖品总量
 */
export const awardsNum = data => {
  return commonInstance.post('/v1/act/awards/num', data);
};

/**
 * 根据语言获取活动列表
 */
export const getLanguage = data => {
  return commonInstance.post('/v1/act/get/language', data);
};

/**
 * 领取体验金
 *
 */
export const receiveCashGift = data => {
  return commonInstance.post('/v1/assets/rewards/cashGift', data);
};

/**
 * 查看需要校验的安全项
 *
 */
export const switchLoginAuth = data => {
  return commonInstance.post('/v1/user/security/login/switch-login-auth', data);
};

/**
 * 2FA设置
 *
 */
export const verifySwitchLoginAuth = data => {
  return commonInstance.post('/v1/user/security/login/verify-switch-login-auth', data);
};


/**
 * 获取注册弹窗内容
 */
export const getRegisterRemind = data => {
  return commonInstance.get('/v1/user/official/getRegisterRemind');
};