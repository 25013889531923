import { communityConfig } from "~/server/common/index"
import { cacheApiFunc } from "../../utils/cacheApi/index"

export const  getBtokLink = cacheApiFunc(async () => {
  const res = await communityConfig()
  if (res.code === "00000") {
    return res.data
  }
  return ''
}, {
  interval: 0
})
