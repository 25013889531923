<template>
  <div class="rejister-wrap" :class="newTheme">
    <div
      :class="[
        'register-input__wrapper',
        error && type ? 'error' : '',
        focus && type ? 'focus' : '',
        focusRegisterInput ? 'focus' : ''
      ]"
      @click="forceFocus"
    >
      <div class="register-input__label" v-show="!error && !focus && !value">
        {{ label }}
      </div>
      <div class="register-input__inner" v-if="focus || value">
        <input
          :type="type"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleChange"
          :value="innerValue"
          ref="innerInput"
        />
      </div>
      <div class="error-message" v-if="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'RegisterInput',
  props: {
    type: String,
    value: String,
    label: String,
    error: String
  },
  data() {
    return {
      focus: false,
      innerValue: this.value,
      focusRegisterInput: false
    };
  },
  computed: {
    ...mapState(['locale', 'newTheme'])
  },
  methods: {
    forceFocus() {
      this.focus = true;
      this.focusRegisterInput = true;
      this.$nextTick(() => {
        this.$refs['innerInput']?.focus();
      });
    },
    handleChange(event) {
      const value = event.target.value;
      this.innerValue = value;
      this.$emit('input', value);
    },
    handleFocus() {
      this.focus = true;
      this.$emit('focus');
    },
    handleBlur() {
      this.focus = false;
      this.focusRegisterInput = false;
      this.$emit('blur');
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerValue = newValue;
      }
    },
    innerValue(newValue) {
      if (newValue !== this.value) {
        this.innerValue = this.value;
      }
    }
  }
};
</script>

<style scoped lang="less">

.rejister-wrap{
  &.white {
     .register-input__wrapper{
      background: #e4e4e7;
    }
  }
  &.black{
    .register-input__wrapper{
      background: @black-bg-input-V2;
    }
  }
}

.register-input__wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 432px;
  height: 48px;
  border-radius: 16px;
  padding-left: 16px;
  // .medium-font();
  flex: 1;

  &.focus {
    border-radius: 16px;
    border: 1px solid @black-border-hover-V2;
    background: unset;

    .register-input__label {
      font-size: 12px;
      color: @text-black;
      line-height: 12px;
      position: absolute;
      top: 11px;
      left: 26px;
      display: none;
    }
  }
  &.error {
    border-radius: 16px;
    border: 1px solid @red-color;

    .register-input__label {
      color: @red-color;
      display: none;
    }
  }

  .register-input__label {
    width: 374px;
    font-size: 14px;
    color:  @black-text-tertiary-V2;
    line-height: 48px;
    flex: 1;
    transition: all 0.3s linear;
  }

  .register-input__inner {
    input {
      border: none;
      outline: none;
      font-size: 14px;
      color: @black-text-primary-V2;
      line-height: 46px;
      width: 374px;
      background: unset;
    }
  }

  .error-message {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    color: @red-color;
    top: 58px;
    left: 0px;
    .regular-font();
  }
}
</style>
