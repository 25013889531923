<template>
  <div class="lang-main">
    <div class="nav-wrap">
      <div style="width:100px;"></div>
      <div>
        <ul class="nav-menu" :class="{ en: locale == 'en' }">
          <li>
            <a href="javascript:;">
              <img src="~/assets/img/common/language-change-ico.svg" />
            </a>
            <div
              class="subnav language-unit clearfix rtlRight"
              :class="{ 'language-unit-en': locale == 'en' }"
            >
              <ul class="subnav-item">
                <div class="language-tit">{{ $t('new_index.header.language_tit') }}</div>
                <li v-for="item in langList"
                  :key="item.lang"
                  :class="{ active: $t('links.lang') == item.name }"
                  @click="switchLang(item.lang)"
                >
                  {{item.name}}
                </li>
              </ul>
            </div>
          </li>
<!--                  <li style="margin-right:-13px;">-->
<!--                    <a-->
<!--                      href="javascript:;"-->
<!--                      style="width:17px; height:17px; cursor:pointer;align-items: center"-->
<!--                      @click.stop="changeNewTheme"-->
<!--                    >-->
<!--                      <div-->
<!--                        :class="newTheme === 'black' ? 'icon-theme-sunny' : 'icon-theme-moon'"-->
<!--                      ></div>-->
<!--                    </a>-->
<!--                  </li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { langCodeMap, languageKeyMap } from '~/config/languageMapping';
import { getLangUrl } from "~/utils/utils";

export default {
  name: 'LangOption',
  data() {
    return {
      langList: [
        {
          lang: 'zh-CN',
          name: '简体中文'
        },
        {
          lang: 'en',
          name: 'English'
        },
        {
          lang: 'zh-TW',
          name: '繁體中文'
        },
        {
          lang: 'ko',
          name: '한국어'
        },
        {
          lang: 'vi',
          name: 'Tiếng Việt'
        },
        // {
        //   lang: 'ja',
        //   name: '日本語'
        // }
      ]
    };
  },
  computed: {
    ...mapState(['locale', 'newTheme'])
  },
  mounted() {
  },
  methods: {
    // 切换语言
    switchLang(lang) {
      var reg = new RegExp('^/' + lang + '/');
      if (reg.test(this.$route.fullPath)) {
        return;
      }
      let tmp = getLangUrl(lang, this.$route.fullPath);
      // let tmp = this.$route.fullPath.replace(/^\/[^\/]+/, `/${lang}`);
      if (this.langStr) {
        let tmpType = 0;
        if (lang == 'zh-CN') tmpType = 1;
        if (lang == 'en') tmpType = 0;
        if (lang == 'ja') tmpType = 2;
        if (lang == 'ko') tmpType = 3;
        if (lang == 'vi') tmpType = 4;
        if (lang == 'zh-TW') tmpType = 5;
        if (lang == 'ru') tmpType = 6;
        if (lang == 'es') tmpType = 7;
        if (lang == languageKeyMap.fa) tmpType = langCodeMap.fa;
        tmp = tmp.replace('languageType=' + this.langStr, 'languageType=' + tmpType);
      }
      this.$router.push(tmp);
    }
  }
};
</script>

<style lang="less" scoped>
.lang-main {
    position: absolute;
    right: 0px;
    z-index: 4;
    .flex();
    .flex-cross-center();

    .lang-show {
      font-size: 16px;
      cursor: pointer;
      &:hover {
      }
    }

    .lang-flag {
      display: inline-block;
      width: 15px;
      height: 12px;
      margin-right: 8px;

      &.cn {
        background: #fff url('~assets/img/expressly/flag-cn.png') center bottom no-repeat;
        background-size: cover;
      }

      &.en {
        background: #fff url('~assets/img/expressly/flag-en.png') center bottom no-repeat;
        background-size: cover;
      }

      &.ja {
        background: #fff url('~assets/img/expressly/flag-ja.png') center bottom no-repeat;
        background-size: cover;
      }

      &.ko {
        background: #fff url('~assets/img/expressly/flag-ko.png') center bottom no-repeat;
        background-size: cover;
      }

      &.vi {
        background: #fff url('~assets/img/expressly/flag-vi.png') center bottom no-repeat;
        background-size: cover;
      }

      &.tw {
        background: #fff url('~assets/img/expressly/flag-tw.png') center bottom no-repeat;
        background-size: cover;
      }

      &.ru {
        background: #fff url('~assets/img/expressly/flag-ru.png') center bottom no-repeat;
        background-size: cover;
      }

      &.es {
        background: #fff url('~assets/img/expressly/flag-es.png') center bottom no-repeat;
        background-size: cover;
      }
    }

    .icon-next2 {
      display: inline-block;
      transform: scale(0.6) rotate(90deg);
    }

    .lang-list-box {
      .pr();
      background: #fff;

      ul {
        .pa();
        right: -2px;
        top: 20px;
        z-index: 3;
        width: 122px;
        box-shadow: 0 0 20px 0 rgba(164, 164, 164, 0.2);
        background: #fff;
        display: none;

        li {
          height: 40px;
          line-height: 40px;
          padding-left: 10px;
          font-size: 16px;
          color: @text-black;
          cursor: pointer;
          transition: all 0.35s;

          &:hover {
            background-color: #ecf3ff;
          }

          &.active {
            color: @yellow-P3;
          }
        }
      }
    }

    .register-input__wrapper {
      margin-top: 32px;
      // background: @gray-P1-alpha10;
    }
  }
</style>
