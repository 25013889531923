/***
 * 谷歌分析 发送代码
 * 注册最后一步 进行统计
 */

 export const reg_ga = ()=>{
    try {
        gtag('event', 'reg_final', {
            'event_category': 'reg',
          });
    } catch(err){
      console.log(err)
    }

 }